body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Montserrat;
}

html,
body,
.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
